.PaymentGatesItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 20px;
}

.PaymentGatesItem--white {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 48px;
  height: 28px;
  border-radius: 5px;
  background: white;
}
