.SignInPhone {
  text-align: center;
}

  .SignInPhone--incorrect .SignIn__header {
      margin-bottom: 26px;
    }

  .SignInPhone--incorrect .SignInPhone__code {
      margin-top: 45px;
    }

  .SignInPhone--locked .SignInPhone__retry span {
    color: var(--main);
    pointer-events: none;
  }

  .SignInPhone__or {
    margin: 11px 0 13px 0;
    font-size: 13px;
    line-height: 16px;
  }

  .SignInPhone__phone {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }

  .SignInPhone__phone input {
      text-align: center;
    }

  .SignInPhone__continue {
    width: 100%;
    margin-top: 18px;
  }

  .SignInPhone__error {
    width: 100%;
    margin-top: 5px;
    color: var(--red);
    font-size: 15px;
    text-align: center;
  }

  .SignInPhone__retry {
    padding-top: 40px;
    padding-bottom: 41.5px;
    font-weight: 600;
    text-align: center;
  }

  .SignInPhone__retry span {
      color: var(--accent);
    }

  .SignInPhone__code {
    margin: 40px 0;
  }

  .SignInPhone__code .Input {
      -webkit-box-flex: 0;
          -ms-flex: 0 0 48px;
              flex: 0 0 48px;
    }

  .SignInPhone__code .Input__chain {
        width: 228px;
        height: 48px;
        margin: auto;
      }

  .SignInPhone__code .Input__field {
        font-weight: 500;
        font-size: 24px;
        line-height: 22px;
      }

  .SignInPhone__phoneCode {
    padding-top: 6px;
  }

  .SignInPhone__dropdown {
    margin-bottom: 47px;
    color: var(--main);
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.8px;
  }

  .SignInPhone__dropdown .Button__icon {
      margin-left: 2px;
      -webkit-transform: translate(2px, 2px);
              transform: translate(2px, 2px);
    }

  .SignInPhone__dropdown .Button__icon path {
        stroke: var(--main);
      }