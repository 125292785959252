@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
:root {
  /* Design system colors */
  --tone-200: rgba(5, 5, 5, 0.06);
  --tone-300: rgba(0, 0, 0, 0.11);
  --tone-400: rgba(0, 0, 0, 0.28);
  --tone-500: rgba(0, 0, 0, 0.51);
  --tone-600: rgba(0, 0, 0, 0.65);
  --tone-700: rgba(0, 0, 0, 0.76);
  --tone-800: rgba(0, 0, 0, 0.86);
  --tone-900: rgba(0, 0, 0, 0.98);

  --absolute-100: #ffffff;
  --absolute-200: #f0f0f0;
  --absolute-300: #e3e3e3;
  --absolute-700: #3d3d3d;
  --absolute-800: #1f1f1f;
  --absolute-900: #050505;

  --aquamarine-500: rgba(1, 135, 144, 1);

  --red-100: rgba(228, 70, 86, 0.1);
  --red-500: rgba(211, 53, 69, 1);

  --blue-100: rgba(0, 122, 255, 0.1);
  --blue-400: rgba(0, 122, 255, 1);

  --text-secondary: rgba(0, 0, 0, 0.54);
  --text-disabled: rgba(0, 0, 0, 0.38);

  --white: white;
  --red: #ff3b2f;
  --light-red: #e44656;
  --transparent-red: rgba(228, 70, 86, 0.1);
  --light-footer-gray: #f1f1f1;
  --light-footer-bg-gray: #f8f8f8;
  --light-text-gray: rgba(0, 0, 0, 0.51);
  --text-black: rgba(0, 0, 0, 0.98);
  --text-black-title-popup: rgba(46, 46, 45, 1);
  --background-light-secondary: #f2f2f7;
  --popup-background: rgba(0, 0, 0, 0.28);
  --gray-border: rgba(0, 0, 0, 0.11);
  --gray-border-light: rgba(194, 194, 194, 0.5);
  --dark-gray: #333333;
  --light-gray: #d9d9d9;
  --medium-gray: #979797;
  --medium-dark-gray: #646464;
  --disabled-gray: #ebebeb;
  --disabled-dark-gray: #c2c2c2;
  --placeholder-gray: #f1f1f1;
  --white: white;
  --black: black;
  --light-black: rgba(61, 61, 61, 1);
  --main: #5648f8;
  --blue: rgba(0, 122, 255, 1);
  --link-blue: rgba(0, 106, 219, 1);
  --medium-main: #c1bbff;
  --light-main: #e7eafb;
  --accent: #22ccd8;
  --light-cookie-accent: rgba(233, 250, 251, 1);
  --light-accent: rgba(34, 204, 216, 0.2);
  --light-background-accent: rgba(144, 247, 254, 0.08);
  --lightest-accent: #b3eef2;
  --mts-red: #e30611;
  --yelow: #ff9f0a;
  --aquamarine: rgba(34, 204, 216, 0.1);

  /* New colors */
  --black-bg: var(--absolute-700);
  --black-hover: var(--absolute-800);

  /* Border-radius variants */
  --radius-sx: 4px;
  --radius-s: 8px;
  --radius-m: 16px;
  --radius-l: 24px;
  --radius-xl: 32px;

  /* Box-shadows */
  --shadow-xl: 0px 25px 59px rgba(0, 0, 0, 0.2);
  --shadow-m: 0px 16px 24px 0px rgba(0, 0, 0, 0.15);

  /**
   * Fonts using while preloading Lato
   * Or if preloading failed
   */
  --fonts: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;

  --font-family-main: Roboto;

  --font-size-heading-first: 30px;
  --font-size-heading-second: 22px;
  --font-size-heading-third: 18px;

  /**
   * Margin for the product card
   * Is using in the product grids and sliders
   */
  --product-margin: 8px;

  /**
   * Height of the `Header` component
   */
  --header-height: 84px;

  /**
   * Margins between content and page body
   * On desktop does not make sense because numeric value is unknown
   * 0 here on desktop to not to violate calculations
   */
  --wrapper-margin: 0;
  --wrapper-padding: 120px;

  /**
   * Cards spacings and width
   */

  --card-spacing: 16px;

  --card-width: 282px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
:root {
    --product-margin: 7.5px
}
  }
@media only screen and (max-width: 767px) {
:root {
    --product-margin: 5px
}
  }
@media only screen and (max-width: 767px) {
:root {
    --header-height: 54px
}
  }
@media only screen and (min-width: 768px) and (max-width: 991px),only screen and (max-width: 767px) {
:root {
    --wrapper-margin: 15px
}
  }
@media only screen and (min-width: 992px) {
:root {
    --card-spacing: 24px
}
  }
@media only screen and (min-width: 992px) {
:root {
    --card-width: calc((100% - (var(--card-spacing) * 2)) / 3)
}
  }
/* TODO: complete */
/* 1600 */
/* 1300 */
/* 992 */
/* 992 */
/* 768 */
/* 320 */
/**
 * Scaling to real size animation
 * With reversed animation
 */
@-webkit-keyframes jump-in {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}
@keyframes jump-in {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}
@-webkit-keyframes jump-out {
  to {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}
@keyframes jump-out {
  to {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}
/**
 * Horizontally sliding animation from left to right
 * With reversed animation
 */
@-webkit-keyframes slide-in {
  from {
    -webkit-transform: translateX(-40%);
            transform: translateX(-40%);
  }
}
@keyframes slide-in {
  from {
    -webkit-transform: translateX(-40%);
            transform: translateX(-40%);
  }
}
@-webkit-keyframes slide-out {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to: {
    -webkit-transform: translateX(-40%);
            transform: translateX(-40%);
  }
}
@keyframes slide-out {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to: {
    -webkit-transform: translateX(-40%);
            transform: translateX(-40%);
  }
}
/**
 * Coloring animation (increasing opacity)
 * With reversed animation
 */
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  to {
    opacity: 0;
  }
}
/**
 * Spinning on 360deg animation
 */
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
/**
 * Rising up (to endpoint popsition) animation
 */
@-webkit-keyframes rise {
  from {
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes rise {
  from {
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
/**
 * Jumping down (to endpoint popsition) animation
 */
@-webkit-keyframes down {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
}
@keyframes down {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
}
.Popup {
  position: fixed;
  display: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.28);
  opacity: 0;
  font-family: Roboto;
  font-family: var(--font-family-main);
  z-index: 1000;  
}
.Popup--visible {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    opacity: 1;
  }
body[\:has\(.Popup--visible\)] {
      overflow: hidden;
    }
body:has(.Popup--visible) {
      overflow: hidden;
    }
.Popup__window {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    grid-gap: 16px;
    gap: 16px;
    position: relative;
    min-width: 384px;
    max-width: 790px;
    max-height: min(80%, 800px);
    margin: 50px;
    padding: 24px;
    border-radius: 32px;
    border-radius: var(--radius-xl);
    background: white;
    background: var(--white);
    -webkit-box-shadow: 0px 25px 59px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 25px 59px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: var(--shadow-xl);
            box-shadow: var(--shadow-xl);
    overflow: hidden;
  }
@media only screen and (max-width: 767px) {
.Popup__window {
      min-width: unset;
      max-height: 95%;
      margin: 10px;
      -webkit-box-shadow: none;
              box-shadow: none
  }
    }
@media only screen and (min-width: 768px) and (max-width: 991px) {
.Popup__window {
      max-width: 80%;
      max-height: 80%
  }
    }
.Popup__header {
    width: -webkit-fill-available;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
.Popup__title {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    letter-spacing: -0.01em;
    color: rgba(0, 0, 0, 0.98);
  }
.Popup__button--close {
      background: #f2f2f7;
      border-radius: 50%;
      height: 30px;
      min-width: 30px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
    }
.Popup__button--close path {
        stroke: rgba(60, 60, 67, 0.6);
      }
.Popup__button--back {
      position: absolute;
      z-index: 1;
    }
.Popup__content {
    position: relative;
    overflow-y: auto;
    overflow-x: clip;
    /* margin for scrollbar */
    margin-right: -10px;
    padding-right: 10px;
  }
.Popup__content__bottomButtons {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      grid-gap: 16px;
      gap: 16px;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
    }
@media only screen and (max-width: 767px) {
.Popup__content__bottomButton {
        margin: 11px 0 16px
    }
      }
@media only screen and (min-width: 768px) and (max-width: 991px),only screen and (min-width: 992px) {
.Popup__content__bottomButton {
        width: 140px;
        min-width: -webkit-fit-content;
        min-width: -moz-fit-content;
        min-width: fit-content;
        justify-self: end
    }
      }
.Popup__content__bottomButton--alt {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        padding-left: 11px;
        padding-right: 11px;
      }
.Popup--appearing {
    -webkit-animation: fade-in;
            animation: fade-in;
  }
.Popup--appearing .Popup__window {
      -webkit-animation: jump-in;
              animation: jump-in;
    }
.Popup--disappearing {
    -webkit-animation: fade-out;
            animation: fade-out;
  }
.Popup--disappearing .Popup__window {
      -webkit-animation: jump-out;
              animation: jump-out;
    }
.Popup .Button__icon {
    margin-left: 0;
  }
.Popup--bottomSheet .Popup__window {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        padding: 24px 16px 42px;

        position: fixed;
        width: 100%;
        bottom: 0px;
        margin: 0;

        background: white;

        background: var(--white);
        border-radius: 32px 32px 0px 0px;
      }
.Popup--bottomSheet.Popup--appearing .Popup__window {
      -webkit-animation: rise;
              animation: rise;
    }
.Popup--bottomSheet.Popup--disappearing .Popup__window {
      -webkit-animation: down;
              animation: down;
    }
.Popup--return {
    background: white;
    background: var(--white);
  }
.Popup--return .Popup__window {
        min-height: 100%;
        max-height: 100%;
        width: 100%;
        padding: 0 16px 16px 16px;
        margin: 0;
        -webkit-animation: unset;
                animation: unset;
      }
.Popup--return .Popup__header {
        min-height: 24px;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: start;
      }
.Popup--return .Popup__button--back {
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: start;
        padding: 8px;
        position: relative;
      }
.Popup--return .Popup__content {
        padding-bottom: 32px;
      }
.Popup--return.Popup--appearing {
      -webkit-animation: slide-in;
              animation: slide-in;
    }
.Popup--return.Popup--disappearing {
      -webkit-animation: slide-out;
              animation: slide-out;
    }
/* This solution is for devices with small screen resolutions */
@media only screen and (max-width: 375px) and (max-height: 667px) {
    .Popup .Popup__window {
      padding: 24px 16px 15px;
    }

    .Popup .Popup__content__bottomButton { 
      margin: 0;
    }

    .Popup .Calendar .rdp {
      margin: 0;
      --rdp-cell-size: 35px;
    }

    .Popup .Calendar {
      position: relative;
      min-height: -webkit-max-content;
      min-height: -moz-max-content;
      min-height: max-content;
      margin-bottom: 20px;
    }
  }
