.PaymentGates {
  --payment-gates-margin: 10px;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  grid-gap: var(--payment-gates-margin);
  gap: var(--payment-gates-margin);
}
