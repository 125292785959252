/**
 * Scaling to real size animation
 * With reversed animation
 */
@-webkit-keyframes jump-in {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}
@keyframes jump-in {
  from {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}
@-webkit-keyframes jump-out {
  to {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}
@keyframes jump-out {
  to {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}
/**
 * Horizontally sliding animation from left to right
 * With reversed animation
 */
@-webkit-keyframes slide-in {
  from {
    -webkit-transform: translateX(-40%);
            transform: translateX(-40%);
  }
}
@keyframes slide-in {
  from {
    -webkit-transform: translateX(-40%);
            transform: translateX(-40%);
  }
}
@-webkit-keyframes slide-out {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to: {
    -webkit-transform: translateX(-40%);
            transform: translateX(-40%);
  }
}
@keyframes slide-out {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to: {
    -webkit-transform: translateX(-40%);
            transform: translateX(-40%);
  }
}
/**
 * Coloring animation (increasing opacity)
 * With reversed animation
 */
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
}
@-webkit-keyframes fade-out {
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  to {
    opacity: 0;
  }
}
/**
 * Spinning on 360deg animation
 */
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
/**
 * Rising up (to endpoint popsition) animation
 */
@-webkit-keyframes rise {
  from {
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes rise {
  from {
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
/**
 * Jumping down (to endpoint popsition) animation
 */
@-webkit-keyframes down {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
}
@keyframes down {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
}
.Header__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.Home-logo {
  -webkit-animation: spin infinite 20s linear;
          animation: spin infinite 20s linear;
  height: 80px;
}
.Home-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: var(--white);
}
.Home-intro {
  font-size: large;
  max-width: 1200px;
  margin: 0 auto;
}
.Home-resources {
  list-style: none;
}
.Home-resources > li {
  display: inline-block;
  padding: 1rem;
}
.Home ul {
  list-style-type: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.Home ul li {
  margin: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.selected {
  font-weight: bold;
  text-decoration: none;
}
