@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
:root {
  /* Design system colors */
  --tone-200: rgba(5, 5, 5, 0.06);
  --tone-300: rgba(0, 0, 0, 0.11);
  --tone-400: rgba(0, 0, 0, 0.28);
  --tone-500: rgba(0, 0, 0, 0.51);
  --tone-600: rgba(0, 0, 0, 0.65);
  --tone-700: rgba(0, 0, 0, 0.76);
  --tone-800: rgba(0, 0, 0, 0.86);
  --tone-900: rgba(0, 0, 0, 0.98);

  --absolute-100: #ffffff;
  --absolute-200: #f0f0f0;
  --absolute-300: #e3e3e3;
  --absolute-700: #3d3d3d;
  --absolute-800: #1f1f1f;
  --absolute-900: #050505;

  --aquamarine-500: rgba(1, 135, 144, 1);

  --red-100: rgba(228, 70, 86, 0.1);
  --red-500: rgba(211, 53, 69, 1);

  --blue-100: rgba(0, 122, 255, 0.1);
  --blue-400: rgba(0, 122, 255, 1);

  --text-secondary: rgba(0, 0, 0, 0.54);
  --text-disabled: rgba(0, 0, 0, 0.38);

  --white: white;
  --red: #ff3b2f;
  --light-red: #e44656;
  --transparent-red: rgba(228, 70, 86, 0.1);
  --light-footer-gray: #f1f1f1;
  --light-footer-bg-gray: #f8f8f8;
  --light-text-gray: rgba(0, 0, 0, 0.51);
  --text-black: rgba(0, 0, 0, 0.98);
  --text-black-title-popup: rgba(46, 46, 45, 1);
  --background-light-secondary: #f2f2f7;
  --popup-background: rgba(0, 0, 0, 0.28);
  --gray-border: rgba(0, 0, 0, 0.11);
  --gray-border-light: rgba(194, 194, 194, 0.5);
  --dark-gray: #333333;
  --light-gray: #d9d9d9;
  --medium-gray: #979797;
  --medium-dark-gray: #646464;
  --disabled-gray: #ebebeb;
  --disabled-dark-gray: #c2c2c2;
  --placeholder-gray: #f1f1f1;
  --white: white;
  --black: black;
  --light-black: rgba(61, 61, 61, 1);
  --main: #5648f8;
  --blue: rgba(0, 122, 255, 1);
  --link-blue: rgba(0, 106, 219, 1);
  --medium-main: #c1bbff;
  --light-main: #e7eafb;
  --accent: #22ccd8;
  --light-cookie-accent: rgba(233, 250, 251, 1);
  --light-accent: rgba(34, 204, 216, 0.2);
  --light-background-accent: rgba(144, 247, 254, 0.08);
  --lightest-accent: #b3eef2;
  --mts-red: #e30611;
  --yelow: #ff9f0a;
  --aquamarine: rgba(34, 204, 216, 0.1);

  /* New colors */
  --black-bg: var(--absolute-700);
  --black-hover: var(--absolute-800);

  /* Border-radius variants */
  --radius-sx: 4px;
  --radius-s: 8px;
  --radius-m: 16px;
  --radius-l: 24px;
  --radius-xl: 32px;

  /* Box-shadows */
  --shadow-xl: 0px 25px 59px rgba(0, 0, 0, 0.2);
  --shadow-m: 0px 16px 24px 0px rgba(0, 0, 0, 0.15);

  /**
   * Fonts using while preloading Lato
   * Or if preloading failed
   */
  --fonts: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;

  --font-family-main: Roboto;

  --font-size-heading-first: 30px;
  --font-size-heading-second: 22px;
  --font-size-heading-third: 18px;

  /**
   * Margin for the product card
   * Is using in the product grids and sliders
   */
  --product-margin: 8px;

  /**
   * Height of the `Header` component
   */
  --header-height: 84px;

  /**
   * Margins between content and page body
   * On desktop does not make sense because numeric value is unknown
   * 0 here on desktop to not to violate calculations
   */
  --wrapper-margin: 0;
  --wrapper-padding: 120px;

  /**
   * Cards spacings and width
   */

  --card-spacing: 16px;

  --card-width: 282px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
:root {
    --product-margin: 7.5px
}
  }
@media only screen and (max-width: 767px) {
:root {
    --product-margin: 5px
}
  }
@media only screen and (max-width: 767px) {
:root {
    --header-height: 54px
}
  }
@media only screen and (min-width: 768px) and (max-width: 991px),only screen and (max-width: 767px) {
:root {
    --wrapper-margin: 15px
}
  }
@media only screen and (min-width: 992px) {
:root {
    --card-spacing: 24px
}
  }
@media only screen and (min-width: 992px) {
:root {
    --card-width: calc((100% - (var(--card-spacing) * 2)) / 3)
}
  }
/* TODO: complete */
/* 1600 */
/* 1300 */
/* 992 */
/* 992 */
/* 768 */
/* 320 */
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}
html {
  margin: 0;
  padding: 0;
}
body {
  color: #333333;
  color: var(--dark-gray);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;
  font-family: var(--fonts);
  font-size: 17px;
  font-weight: normal;
  /* letter-spacing: -0.63px; */
  line-height: 1.18;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
body.no-scroll {
    overflow: hidden;
  }
.wf-active body {
  font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;
  font-family: Lato, var(--fonts);
}
/**
 * For overloading default browser styles of the components
 */
.unrecognizable {
  background: none;
  border: none;
}
.unrecognizable--link,
  .unrecognizable--link:visited {
    color: #333333;
    color: var(--dark-gray);
  }
.bg-gray-200 {
  color: #22ccd8;
  color: var(--accent);
}
.flex {
  color:  rgba(0, 0, 0, 0.76);
  color:  var(--tone-700);
}
.font-bold {
  color: #22ccd8;
  color: var(--accent);
}
.unic input:checked~.toggle__dot {
  background-color: #22ccd8 !important;
  background-color: var(--accent) !important;
}
.unic-bar {
  background-color:rgba(40,208,211,0.65098);
  border-radius: 20px;
  margin-bottom: 12px;
}
.unic-bar .unic-modal-content {
    border-radius: 20px;
  }
.unic .rounded-t-lg {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.unic {
  background-image: linear-gradient(40deg, rgba(199,199,199,0.52157), rgba(239,239,239,0.55294));
}
.unic path {
    fill: #22ccd8;
    fill: var(--accent);
  }
.unic .unic-modal-content {
    border-radius: 20px;
  }
.unic-badge {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
}
.unic::after {
  content: '';
  position: absolute; 
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 15px;
  height: 12px;
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="%23fff" viewBox="0 0 256 286"%3E%3Cpath d="M166.891 277.962h-.685c-6.312-.192-15.401-3.187-22.74-16.468-3.27-5.912-6.274-13.973-9.202-24.53-5.02-18.08-9.279-41.688-13.386-64.528-3.194-17.735-6.502-36.084-10.001-50.518a406.582 406.582 0 0 0-2.091-8.253c-.114.346-.19.691-.304 1.075-3.08 10.48-6.085 23.531-9.013 36.16-3.726 16.238-7.605 33.013-11.902 45.796-2.7 7.908-5.248 13.666-8.252 18.119-7.53 11.401-16.656 12.668-21.409 12.361-16.656-.96-24.945-19.04-30.84-36.622-4.753-14.241-9.43-33.742-14.031-57.887C5.39 92.13.447 50.057.066 45.912-.73 37.045 5.657 29.137 14.48 28.33c8.747-.806 16.58 5.68 17.379 14.587.342 3.378 4.41 38.195 10.951 74.854 6.237 34.702 11.637 54.356 15.553 65.412.38-1.152.723-2.38 1.141-3.685 3.08-10.365 6.046-23.378 8.898-35.93 3.727-16.2 7.568-32.937 11.865-45.72 3.118-9.29 10.42-31.016 28.71-31.016h.038c20.04.038 27.189 26.18 30.65 38.771 4.601 16.852 8.48 38.348 12.586 61.074 3.385 18.848 6.959 38.348 10.724 53.972 1.825 7.37 3.46 12.783 4.791 16.775 2.548-5.681 5.666-14.165 8.747-26.295 5.399-21.152 8.86-45.719 9.468-67.37 1.103-41.074-7.453-81.687-25.478-120.765-3.726-8.1-.304-17.697 7.72-21.459 7.985-3.8 17.454-.307 21.219 7.793 20.154 43.684 29.737 89.25 28.444 135.353-.761 27.293-5.59 58.502-12.929 83.53-3.841 12.975-8.024 23.57-12.587 31.362C185.296 271.781 176.664 278 166.853 278l.038-.038Z"%3E%3C/path%3E%3C/svg%3E');
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none;
}
.Page {
  padding-top: 24px;
  padding-bottom: 48px;
}
.Wrapper {
  max-width: 81.25rem; /* 1180px + 120px padding */
  margin: auto;
  padding: 0 60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px),only screen and (max-width: 767px) {
.Wrapper {
    margin: 0 0;
    margin: 0 var(--wrapper-margin);
    padding: 0
}
  }
.Wrapper__product-page {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
.Wrapper__overload {
    display: block;
    width: calc(100% + 2 * 0);
    width: calc(100% + 2 * var(--wrapper-margin));
    margin: 0 calc(-1 * 0);
    margin: 0 calc(-1 * var(--wrapper-margin));
  }
.Wrapper--wide {
    max-width: 97.5em;
    padding: 0 30px;
  }
.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.grow-0 {
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}
.grow-expand {
  -webkit-box-flex: 1000;
      -ms-flex-positive: 1000;
          flex-grow: 1000;
}
.shrink-0 {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.flex-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.right {
  text-align: right;
}
a {
  text-decoration: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
a[disabled] {
    pointer-events: none;
  }
.col-minimal {
  -webkit-box-flex: unset;
      -ms-flex-positive: unset;
          flex-grow: unset;
}
@media only screen and (min-width: 1300px) {
.col-xlg-3 {
    -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
    max-width: 25%
}
  }
@media only screen and (max-width: 767px) {
.d-xs-none {
    display: none
}
  }
@media only screen and (min-width: 768px) and (max-width: 991px) {
.d-md-none {
    display: none
}
  }
.d-lg-md-only {
  display: block;
}
@media only screen and (max-width: 767px) {
.d-lg-md-only {
    display: none
}
  }
.d-xs-only {
  display: none;
}
@media only screen and (max-width: 767px) {
.d-xs-only {
    display: block
}
  }
.d-md-only {
  display: none;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
.d-md-only {
    display: block
}
  }
.d-md-xs-only {
  display: none;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
.d-md-xs-only {
    display: block
}
  }
@media only screen and (max-width: 767px) {
.d-md-xs-only {
    display: block
}
  }
@media only screen and (min-width: 768px) {
  .col-md-4-5 {
    -ms-flex-preferred-size: 37.5%;
        flex-basis: 37.5%;
    max-width: 37.5%;
  }

  .col-md-7-5 {
    -ms-flex-preferred-size: 62.5%;
        flex-basis: 62.5%;
    max-width: 62.5%;
  }
}
@media only screen and (min-width: 768px) {
  .col-md-5-5 {
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    max-width: 50%;
  }

  .col-md-6-5 {
    -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    max-width: 50%;
  }
}
/* Initial loading state */
#root {
  min-height: 100vh;
}
/* Initial spinner */
#initial-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(255, 255, 255);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 9999;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
}
#initial-loader.hidden {
  opacity: 0;
}
.SpinnerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(255, 255, 255);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 9999;
  pointer-events: none;
}
