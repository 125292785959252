.SignInPassword {
  text-align: center;
}

  .SignInPassword .SignIn__holo {
    top: -8px;
  }

  .SignInPassword__form {
    padding: 12px 0;
  }

  .SignInPassword__field {
    margin-top: 18px;
  }

  .SignInPassword__check {
    margin-top: 28px;
  }

  .SignInPassword__spinner {
    position: absolute;
    left: 12px;
  }

  .SignInPassword__error {
    margin-top: 5px;
    color: var(--red);
    font-size: 15px;
  }
