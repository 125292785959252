.Unsubscribe__title {
    margin-bottom: 46px;
    margin-top: 46px;
    font-size: 30px;
    line-height: 36px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
  }
  .Unsubscribe__subtitle {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 36px;
  }
  .Unsubscribe__button {
      margin-bottom: 60px;
      margin-top: 36px; 
      max-width: 236px;
  }
