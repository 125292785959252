@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
:root {
  /* Design system colors */
  --tone-200: rgba(5, 5, 5, 0.06);
  --tone-300: rgba(0, 0, 0, 0.11);
  --tone-400: rgba(0, 0, 0, 0.28);
  --tone-500: rgba(0, 0, 0, 0.51);
  --tone-600: rgba(0, 0, 0, 0.65);
  --tone-700: rgba(0, 0, 0, 0.76);
  --tone-800: rgba(0, 0, 0, 0.86);
  --tone-900: rgba(0, 0, 0, 0.98);

  --absolute-100: #ffffff;
  --absolute-200: #f0f0f0;
  --absolute-300: #e3e3e3;
  --absolute-700: #3d3d3d;
  --absolute-800: #1f1f1f;
  --absolute-900: #050505;

  --aquamarine-500: rgba(1, 135, 144, 1);

  --red-100: rgba(228, 70, 86, 0.1);
  --red-500: rgba(211, 53, 69, 1);

  --blue-100: rgba(0, 122, 255, 0.1);
  --blue-400: rgba(0, 122, 255, 1);

  --text-secondary: rgba(0, 0, 0, 0.54);
  --text-disabled: rgba(0, 0, 0, 0.38);

  --white: white;
  --red: #ff3b2f;
  --light-red: #e44656;
  --transparent-red: rgba(228, 70, 86, 0.1);
  --light-footer-gray: #f1f1f1;
  --light-footer-bg-gray: #f8f8f8;
  --light-text-gray: rgba(0, 0, 0, 0.51);
  --text-black: rgba(0, 0, 0, 0.98);
  --text-black-title-popup: rgba(46, 46, 45, 1);
  --background-light-secondary: #f2f2f7;
  --popup-background: rgba(0, 0, 0, 0.28);
  --gray-border: rgba(0, 0, 0, 0.11);
  --gray-border-light: rgba(194, 194, 194, 0.5);
  --dark-gray: #333333;
  --light-gray: #d9d9d9;
  --medium-gray: #979797;
  --medium-dark-gray: #646464;
  --disabled-gray: #ebebeb;
  --disabled-dark-gray: #c2c2c2;
  --placeholder-gray: #f1f1f1;
  --white: white;
  --black: black;
  --light-black: rgba(61, 61, 61, 1);
  --main: #5648f8;
  --blue: rgba(0, 122, 255, 1);
  --link-blue: rgba(0, 106, 219, 1);
  --medium-main: #c1bbff;
  --light-main: #e7eafb;
  --accent: #22ccd8;
  --light-cookie-accent: rgba(233, 250, 251, 1);
  --light-accent: rgba(34, 204, 216, 0.2);
  --light-background-accent: rgba(144, 247, 254, 0.08);
  --lightest-accent: #b3eef2;
  --mts-red: #e30611;
  --yelow: #ff9f0a;
  --aquamarine: rgba(34, 204, 216, 0.1);

  /* New colors */
  --black-bg: var(--absolute-700);
  --black-hover: var(--absolute-800);

  /* Border-radius variants */
  --radius-sx: 4px;
  --radius-s: 8px;
  --radius-m: 16px;
  --radius-l: 24px;
  --radius-xl: 32px;

  /* Box-shadows */
  --shadow-xl: 0px 25px 59px rgba(0, 0, 0, 0.2);
  --shadow-m: 0px 16px 24px 0px rgba(0, 0, 0, 0.15);

  /**
   * Fonts using while preloading Lato
   * Or if preloading failed
   */
  --fonts: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;

  --font-family-main: Roboto;

  --font-size-heading-first: 30px;
  --font-size-heading-second: 22px;
  --font-size-heading-third: 18px;

  /**
   * Margin for the product card
   * Is using in the product grids and sliders
   */
  --product-margin: 8px;

  /**
   * Height of the `Header` component
   */
  --header-height: 84px;

  /**
   * Margins between content and page body
   * On desktop does not make sense because numeric value is unknown
   * 0 here on desktop to not to violate calculations
   */
  --wrapper-margin: 0;
  --wrapper-padding: 120px;

  /**
   * Cards spacings and width
   */

  --card-spacing: 16px;

  --card-width: 282px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
:root {
    --product-margin: 7.5px
}
  }
@media only screen and (max-width: 767px) {
:root {
    --product-margin: 5px
}
  }
@media only screen and (max-width: 767px) {
:root {
    --header-height: 54px
}
  }
@media only screen and (min-width: 768px) and (max-width: 991px),only screen and (max-width: 767px) {
:root {
    --wrapper-margin: 15px
}
  }
@media only screen and (min-width: 992px) {
:root {
    --card-spacing: 24px
}
  }
@media only screen and (min-width: 992px) {
:root {
    --card-width: calc((100% - (var(--card-spacing) * 2)) / 3)
}
  }
/* TODO: complete */
/* 1600 */
/* 1300 */
/* 992 */
/* 992 */
/* 768 */
/* 320 */
.Input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.Input__field,
  .Input .PhoneInputInput {
    width: 100%;
    border: none;
    line-height: 1.4;
    font-size: 16px;
    font-weight: 400;
  }
.Input__field:focus, .Input .PhoneInputInput:focus {
      outline: none;
    }
.Input--disabled .Input__field {
      color: rgba(0, 0, 0, 0.38);
      color: var(--text-disabled);
      background-color: inherit;
    }
.Input .PhoneInput {
    --PhoneInputCountryFlag-height: 15px;
    --PhoneInputCountrySelectArrow-width: 8px;
    --PhoneInputCountrySelectArrow-borderWidth: 1.5px;
    --PhoneInputCountrySelectArrow-color: #000000;
    --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  }
.Input .PhoneInputInput {
      padding: 9px 0;
      border-bottom: 1px solid #ebebeb;
      border-bottom: 1px solid var(--disabled-gray);
      font-size: 20px;
      line-height: 24px;
    }
.Input .PhoneInputInput::-webkit-input-placeholder {
        color: #d9d9d9;
        color: var(--light-gray);
      }
.Input .PhoneInputInput::-moz-placeholder {
        color: #d9d9d9;
        color: var(--light-gray);
      }
.Input .PhoneInputInput:-ms-input-placeholder {
        color: #d9d9d9;
        color: var(--light-gray);
      }
.Input .PhoneInputInput::-ms-input-placeholder {
        color: #d9d9d9;
        color: var(--light-gray);
      }
.Input .PhoneInputInput::placeholder {
        color: #d9d9d9;
        color: var(--light-gray);
      }
.Input .PhoneInputCountry {
      width: 68px;
      height: 44px;
      margin-right: 11px;
      padding: 10px 12px;
      border-radius: 8px;
      border-radius: var(--radius-s);
      -webkit-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
              box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    }
.Input .PhoneInputCountryIcon {
      margin-right: 5px;
    }
.Input .PhoneInputCountrySelectArrow {
      opacity: 1;
    }
.Input--focused .PhoneInputInput {
      border-color: #333333;
      border-color: var(--dark-gray);
    }
.Input--card.Input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 2px;
}
.Input--card.Input--outer {
    border-bottom: 1px solid #ebebeb;
    border-bottom: 1px solid var(--disabled-gray);
  }
.Input--card.Input--outer.Input--focused {
      border-color: #333333;
      border-color: var(--dark-gray);
    }
.Input--card.Input .Input__field {
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
    }
.Input--card.Input .Input__field::-webkit-input-placeholder {
        color: #d9d9d9;
        color: var(--light-gray);
      }
.Input--card.Input .Input__field::-moz-placeholder {
        color: #d9d9d9;
        color: var(--light-gray);
      }
.Input--card.Input .Input__field:-ms-input-placeholder {
        color: #d9d9d9;
        color: var(--light-gray);
      }
.Input--card.Input .Input__field::-ms-input-placeholder {
        color: #d9d9d9;
        color: var(--light-gray);
      }
.Input--card.Input .Input__field::placeholder {
        color: #d9d9d9;
        color: var(--light-gray);
      }
.Input--stroked {
  width: 100%;
  height: 44px;
  padding: 0.75em 0.66em;
  border: 1px solid #d9d9d9;
  border: 1px solid var(--light-gray);
  border-radius: 8px;
  border-radius: var(--radius-s);
}
.Input--multiline {
  height: auto;
}
.Input--multiline textarea {
    resize: none;
  }
.Input--outlined.Input {
  height: 44px;
  border: 1px solid #d9d9d9;
  border: 1px solid var(--light-gray);
  border-radius: 8px;
  border-radius: var(--radius-s);
  padding: 0 10px;
  -webkit-transition: border-color 0.3s ease;
  transition: border-color 0.3s ease;
}
.Input--outlined.Input--disabled {
    border-color: #ebebeb;
    border-color: var(--disabled-gray);
  }
.Input--outlined.Input--disabled .Input__field {
        background: none;
      }
.Input--outlined.Input .Input__field {
      height: 100%;
      font-size: 17px;
      line-height: 129%;
      -webkit-transition: color 0.3s ease;
      transition: color 0.3s ease;
    }
.Input--outlined.Input .PhoneInputCountry {
    width: auto;
    height: 100%;
    border-right: 1px solid #d9d9d9;
    border-right: 1px solid var(--light-gray);
    border-radius: 0;
    padding: 0 15px 0 0;
    -webkit-box-shadow: unset;
            box-shadow: unset;
    -webkit-transition: border-right-color 0.3s ease;
    transition: border-right-color 0.3s ease;
  }
.Input--outlined.Input .PhoneInputInput {
    height: 100%;
    padding: 0;
    border: unset;
    font-size: 17px;
    line-height: 129%;
  }
.Input--outlined.Input--success {
    color: #22ccd8 !important;
    color: var(--accent) !important;
  }
.Input--outlined.Input--focused {
    border-color: #22ccd8;
    border-color: var(--accent);
  }
.Input--outlined.Input--focused .PhoneInputCountry {
      border-right-color: #22ccd8;
      border-right-color: var(--accent);
    }
.Input--outlined.Input--error {
    border-color: #ff3b2f;
    border-color: var(--red);
  }
.Input--outlined.Input--error .PhoneInputCountry {
      border-right-color: #ff3b2f;
      border-right-color: var(--red);
    }
