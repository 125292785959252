@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
:root {
  /* Design system colors */
  --tone-200: rgba(5, 5, 5, 0.06);
  --tone-300: rgba(0, 0, 0, 0.11);
  --tone-400: rgba(0, 0, 0, 0.28);
  --tone-500: rgba(0, 0, 0, 0.51);
  --tone-600: rgba(0, 0, 0, 0.65);
  --tone-700: rgba(0, 0, 0, 0.76);
  --tone-800: rgba(0, 0, 0, 0.86);
  --tone-900: rgba(0, 0, 0, 0.98);

  --absolute-100: #ffffff;
  --absolute-200: #f0f0f0;
  --absolute-300: #e3e3e3;
  --absolute-700: #3d3d3d;
  --absolute-800: #1f1f1f;
  --absolute-900: #050505;

  --aquamarine-500: rgba(1, 135, 144, 1);

  --red-100: rgba(228, 70, 86, 0.1);
  --red-500: rgba(211, 53, 69, 1);

  --blue-100: rgba(0, 122, 255, 0.1);
  --blue-400: rgba(0, 122, 255, 1);

  --text-secondary: rgba(0, 0, 0, 0.54);
  --text-disabled: rgba(0, 0, 0, 0.38);

  --white: white;
  --red: #ff3b2f;
  --light-red: #e44656;
  --transparent-red: rgba(228, 70, 86, 0.1);
  --light-footer-gray: #f1f1f1;
  --light-footer-bg-gray: #f8f8f8;
  --light-text-gray: rgba(0, 0, 0, 0.51);
  --text-black: rgba(0, 0, 0, 0.98);
  --text-black-title-popup: rgba(46, 46, 45, 1);
  --background-light-secondary: #f2f2f7;
  --popup-background: rgba(0, 0, 0, 0.28);
  --gray-border: rgba(0, 0, 0, 0.11);
  --gray-border-light: rgba(194, 194, 194, 0.5);
  --dark-gray: #333333;
  --light-gray: #d9d9d9;
  --medium-gray: #979797;
  --medium-dark-gray: #646464;
  --disabled-gray: #ebebeb;
  --disabled-dark-gray: #c2c2c2;
  --placeholder-gray: #f1f1f1;
  --white: white;
  --black: black;
  --light-black: rgba(61, 61, 61, 1);
  --main: #5648f8;
  --blue: rgba(0, 122, 255, 1);
  --link-blue: rgba(0, 106, 219, 1);
  --medium-main: #c1bbff;
  --light-main: #e7eafb;
  --accent: #22ccd8;
  --light-cookie-accent: rgba(233, 250, 251, 1);
  --light-accent: rgba(34, 204, 216, 0.2);
  --light-background-accent: rgba(144, 247, 254, 0.08);
  --lightest-accent: #b3eef2;
  --mts-red: #e30611;
  --yelow: #ff9f0a;
  --aquamarine: rgba(34, 204, 216, 0.1);

  /* New colors */
  --black-bg: var(--absolute-700);
  --black-hover: var(--absolute-800);

  /* Border-radius variants */
  --radius-sx: 4px;
  --radius-s: 8px;
  --radius-m: 16px;
  --radius-l: 24px;
  --radius-xl: 32px;

  /* Box-shadows */
  --shadow-xl: 0px 25px 59px rgba(0, 0, 0, 0.2);
  --shadow-m: 0px 16px 24px 0px rgba(0, 0, 0, 0.15);

  /**
   * Fonts using while preloading Lato
   * Or if preloading failed
   */
  --fonts: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;

  --font-family-main: Roboto;

  --font-size-heading-first: 30px;
  --font-size-heading-second: 22px;
  --font-size-heading-third: 18px;

  /**
   * Margin for the product card
   * Is using in the product grids and sliders
   */
  --product-margin: 8px;

  /**
   * Height of the `Header` component
   */
  --header-height: 84px;

  /**
   * Margins between content and page body
   * On desktop does not make sense because numeric value is unknown
   * 0 here on desktop to not to violate calculations
   */
  --wrapper-margin: 0;
  --wrapper-padding: 120px;

  /**
   * Cards spacings and width
   */

  --card-spacing: 16px;

  --card-width: 282px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
:root {
    --product-margin: 7.5px
}
  }
@media only screen and (max-width: 767px) {
:root {
    --product-margin: 5px
}
  }
@media only screen and (max-width: 767px) {
:root {
    --header-height: 54px
}
  }
@media only screen and (min-width: 768px) and (max-width: 991px),only screen and (max-width: 767px) {
:root {
    --wrapper-margin: 15px
}
  }
@media only screen and (min-width: 992px) {
:root {
    --card-spacing: 24px
}
  }
@media only screen and (min-width: 992px) {
:root {
    --card-width: calc((100% - (var(--card-spacing) * 2)) / 3)
}
  }
/* TODO: complete */
/* 1600 */
/* 1300 */
/* 992 */
/* 992 */
/* 768 */
/* 320 */
.Button {
  -webkit-appearance: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: transparent;
  border: none;
  border-radius: 8px;
  border-radius: var(--radius-s);
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 44px;
  padding: 0 19px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  width: 100%;

  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.01em;
}
@media only screen and (max-width: 767px) {
.Button {
    -webkit-transition: 0;
    transition: 0
}
  }
@media (hover: hover) {
    .Button:hover {
      opacity: 0.7;
    }
  }
@media (hover: hover) and only screen and (max-width: 767px) {
    .Button:hover {
        opacity: 1
    }
      }
.Button * {
    pointer-events: none;
  }
/* Sizes */
.Button--S {
    height: 36px;
  }
.Button--M {
    height: 40px;
  }
.Button--L {
    height: 48px;
  }
/* end of sizes */
.Button--fit {
    width: auto;
    min-width: 200px;
  }
.Button--touch {
    height: 40px;
    width: 40px;
    background-color: white;
    background-color: var(--white);
    border-radius: 8px;
    padding: 8px;
    margin: 0;
  }
.Button--touch .Button__icon {
      padding-right: 6px;
    }
.Button--accent {
    border: 2px solid #22ccd8;
    border: 2px solid var(--accent);
    color: white;
    color: var(--white);
  }
.Button--accent .Button__text {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
    }
@media (hover: hover) {
      .Button--accent:hover {
        opacity: 0.7;
      }
    }
.Button--accent-full {
    background-color: #22ccd8;
    background-color: var(--accent);
    color: white;
    color: var(--white);
  }
@media (hover: hover) {
      .Button--accent-full:hover {
        background-color: rgba(1, 135, 144, 1);
        background-color: var(--aquamarine-500);
        opacity: 1;
      }
    }
.Button--simple,
  .Button--simple-main,
  .Button--simple-accent {
    border-radius: 0;
    height: auto;
    padding: 0;
    width: auto;
    color: #333333;
    color: var(--dark-gray);
  }
@media only screen and (max-width: 767px) {
.Button--simple,
  .Button--simple-main,
  .Button--simple-accent {
      margin: 0
  }
    }
.Button--simple-main {
    color: #5648f8;
    color: var(--main);
  }
.Button--simple-accent {
    color: #22ccd8;
    color: var(--accent);
  }
.Button--card {
    position: relative;
    padding: 12px;
    border: 1px solid rgba(0, 0, 0, 0.11);
    border: 1px solid var(--tone-300);
    line-height: 1;
  }
@media (hover: hover) {
      .Button--card:hover {
        border: 1px solid rgba(0, 0, 0, 0.28);
        border: 1px solid var(--tone-400);
        opacity: 1;
      }
    }
@media (hover: hover) {
      .Button--card:activ {
        border: 1px solid rgba(0, 0, 0, 0.51);
        border: 1px solid var(--tone-500);
        opacity: 1;
      }
    }
.Button--card .Button__icon {
      position: absolute;
      left: 12px;
    }
.Button--cardCentered {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: unset;
    border: 1px solid rgba(0, 0, 0, 0.11);
    border: 1px solid var(--gray-border);
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
  }
.Button--stroked {
    border: 1px solid rgba(0, 0, 0, 0.11);
    border: 1px solid var(--tone-300);
  }
[disabled] .Button--stroked {
      border-color: #979797;
      border-color: var(--medium-gray);
      color: #979797;
      color: var(--medium-gray);
      background: none !important;
    }
@media (hover: hover) {
      .Button--stroked:hover {
        background: #333333;
        background: var(--dark-gray);
        color: white;
        color: var(--white);
        opacity: 1;
      }

        .Button--stroked:hover path {
          stroke: white;
          stroke: var(--white);
        }
    }
@media only screen and (max-width: 767px) {
.Button--stroked {
      color: rgba(0, 0, 0, 0.98);
      color: var(--tone-900)
  }
    }
.Button__icon {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
.Button__text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
  }
.Button--main {
    background: #5648f8;
    background: var(--main);
    border: 0;
    color: white;
    color: var(--white);
  }
@media (hover: hover) {
      .Button--main:hover {
        opacity: 0.7;
      }
    }
.Button--bordered {
    border-radius: 100px;
  }
.Button--light {
    background: #22ccd8;
    background: var(--accent);
    border: 0;
    color: white;
    color: var(--white);
  }
@media (hover: hover) {
      .Button--light:hover {
        opacity: 0.7;
      }
    }
.Button--icon-before {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }
.Button--icon-before .Button__icon {
    margin-right: 0.45em;
  }
.Button--icon-after .Button__icon {
    margin-left: 6px;
  }
.Button--icon-behind .Button__icon {
      position: absolute;
    }
.Button--icon-behind .Button__text {
      z-index: 1;
    }
.Button--box {
    background: white;
    background: var(--white);
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    height: 34px;
  }
.Button--tag {
    background: white;
    background: var(--white);
    border: 1px solid #d9d9d9;
    border: 1px solid var(--light-gray);
    border-radius: 5px;
    -webkit-box-shadow: unset;
            box-shadow: unset;
    height: 36px;
    padding: 0 10px;
  }
.Button--semi-transparent {
    width: auto;
    height: 36px;
    padding: 8.5px 8px;
    background: rgba(61, 61, 61, 1);
    color: white;
    color: var(--white);
    font-size: 16px;
    font-weight: 500;
    line-height: 18.75px;
  }
@media (hover: hover) {
      .Button--semi-transparent:hover {
        opacity: 0.7;
      }
    }
.Button--semi-transparent .Button__icon {
      margin-right: 4px;
    }
@media only screen and (max-width: 767px) {
.Button--xs-text-hidden .Button__text {
      display: none
  }
    }
@media only screen and (max-width: 767px) {
.Button--xs-text-hidden .Button__icon {
      margin: 0
  }
    }
@media only screen and (min-width: 768px) and (max-width: 991px) {
.Button--md-text-hidden .Button__text {
      display: none
  }
    }
@media only screen and (min-width: 768px) and (max-width: 991px) {
.Button--md-text-hidden .Button__icon {
      margin: 0
  }
    }
.Button--inactive {
    pointer-events: none;
  }
.Button--container {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0;
    margin: 0;
  }
.Button--container .Button__text {
      width: 100%;
    }
.Button[disabled],
  [disabled] .Button {
    background: rgba(0, 0, 0, 0.11);
    background: var(--tone-300);
    border: rgba(0, 0, 0, 0.11);
    border: var(--tone-300);
    color: rgba(0, 0, 0, 0.28);
    color: var(--tone-400);
  }
.Button--black {
    background: #3d3d3d;
    background: var(--black-bg);
    color: white;
    color: var(--white);
    border: 1px solid transparent;
  }
.Button--black path {
      stroke: white;
      stroke: var(--white);
    }
@media (hover: hover) {
      .Button--black:hover {
        background: #1f1f1f;
        background: var(--black-hover);
        opacity: 1;
      }
    }
.Button--black path {
      stroke: white;
      stroke: var(--white);
    }
.Button--black[disabled],
    [disabled] .Button--black {
      background: rgba(0, 0, 0, 0.11);
      background: var(--tone-300);
      color: rgba(0, 0, 0, 0.28);
      color: var(--tone-400);
    }
