@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
:root {
  /* Design system colors */
  --tone-200: rgba(5, 5, 5, 0.06);
  --tone-300: rgba(0, 0, 0, 0.11);
  --tone-400: rgba(0, 0, 0, 0.28);
  --tone-500: rgba(0, 0, 0, 0.51);
  --tone-600: rgba(0, 0, 0, 0.65);
  --tone-700: rgba(0, 0, 0, 0.76);
  --tone-800: rgba(0, 0, 0, 0.86);
  --tone-900: rgba(0, 0, 0, 0.98);

  --absolute-100: #ffffff;
  --absolute-200: #f0f0f0;
  --absolute-300: #e3e3e3;
  --absolute-700: #3d3d3d;
  --absolute-800: #1f1f1f;
  --absolute-900: #050505;

  --aquamarine-500: rgba(1, 135, 144, 1);

  --red-100: rgba(228, 70, 86, 0.1);
  --red-500: rgba(211, 53, 69, 1);

  --blue-100: rgba(0, 122, 255, 0.1);
  --blue-400: rgba(0, 122, 255, 1);

  --text-secondary: rgba(0, 0, 0, 0.54);
  --text-disabled: rgba(0, 0, 0, 0.38);

  --white: white;
  --red: #ff3b2f;
  --light-red: #e44656;
  --transparent-red: rgba(228, 70, 86, 0.1);
  --light-footer-gray: #f1f1f1;
  --light-footer-bg-gray: #f8f8f8;
  --light-text-gray: rgba(0, 0, 0, 0.51);
  --text-black: rgba(0, 0, 0, 0.98);
  --text-black-title-popup: rgba(46, 46, 45, 1);
  --background-light-secondary: #f2f2f7;
  --popup-background: rgba(0, 0, 0, 0.28);
  --gray-border: rgba(0, 0, 0, 0.11);
  --gray-border-light: rgba(194, 194, 194, 0.5);
  --dark-gray: #333333;
  --light-gray: #d9d9d9;
  --medium-gray: #979797;
  --medium-dark-gray: #646464;
  --disabled-gray: #ebebeb;
  --disabled-dark-gray: #c2c2c2;
  --placeholder-gray: #f1f1f1;
  --white: white;
  --black: black;
  --light-black: rgba(61, 61, 61, 1);
  --main: #5648f8;
  --blue: rgba(0, 122, 255, 1);
  --link-blue: rgba(0, 106, 219, 1);
  --medium-main: #c1bbff;
  --light-main: #e7eafb;
  --accent: #22ccd8;
  --light-cookie-accent: rgba(233, 250, 251, 1);
  --light-accent: rgba(34, 204, 216, 0.2);
  --light-background-accent: rgba(144, 247, 254, 0.08);
  --lightest-accent: #b3eef2;
  --mts-red: #e30611;
  --yelow: #ff9f0a;
  --aquamarine: rgba(34, 204, 216, 0.1);

  /* New colors */
  --black-bg: var(--absolute-700);
  --black-hover: var(--absolute-800);

  /* Border-radius variants */
  --radius-sx: 4px;
  --radius-s: 8px;
  --radius-m: 16px;
  --radius-l: 24px;
  --radius-xl: 32px;

  /* Box-shadows */
  --shadow-xl: 0px 25px 59px rgba(0, 0, 0, 0.2);
  --shadow-m: 0px 16px 24px 0px rgba(0, 0, 0, 0.15);

  /**
   * Fonts using while preloading Lato
   * Or if preloading failed
   */
  --fonts: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;

  --font-family-main: Roboto;

  --font-size-heading-first: 30px;
  --font-size-heading-second: 22px;
  --font-size-heading-third: 18px;

  /**
   * Margin for the product card
   * Is using in the product grids and sliders
   */
  --product-margin: 8px;

  /**
   * Height of the `Header` component
   */
  --header-height: 84px;

  /**
   * Margins between content and page body
   * On desktop does not make sense because numeric value is unknown
   * 0 here on desktop to not to violate calculations
   */
  --wrapper-margin: 0;
  --wrapper-padding: 120px;

  /**
   * Cards spacings and width
   */

  --card-spacing: 16px;

  --card-width: 282px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
:root {
    --product-margin: 7.5px
}
  }
@media only screen and (max-width: 767px) {
:root {
    --product-margin: 5px
}
  }
@media only screen and (max-width: 767px) {
:root {
    --header-height: 54px
}
  }
@media only screen and (min-width: 768px) and (max-width: 991px),only screen and (max-width: 767px) {
:root {
    --wrapper-margin: 15px
}
  }
@media only screen and (min-width: 992px) {
:root {
    --card-spacing: 24px
}
  }
@media only screen and (min-width: 992px) {
:root {
    --card-width: calc((100% - (var(--card-spacing) * 2)) / 3)
}
  }
/* TODO: complete */
/* 1600 */
/* 1300 */
/* 992 */
/* 992 */
/* 768 */
/* 320 */
.Footer {
  background: #f8f8f8;
  background: var(--light-footer-bg-gray);
  padding: 63px 0 120px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
.Footer {
    padding-top: 37px
}
  }
@media only screen and (max-width: 767px) {
.Footer {
    padding: 21px 0 95px
}
  }
.Footer__top {
    padding-bottom: 24px;
  }
.Footer--no-markets .Apps,
  .Footer__label--markets {
    visibility: hidden;
  }
@media only screen and (max-width: 767px) {
.Footer--no-markets .Apps,
  .Footer__label--markets {
      display: none
  }
    }
.Footer--minimal {
    padding: 0;
    padding-bottom: 38px;
  }
.Footer--minimal .Footer__bottom {
    background-image: unset;
  }
.Footer__bottom {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-image: -webkit-gradient(
      linear,
      left bottom, left top,
      color-stop(0, rgba(194, 194, 194, 0.5)),
      to(rgba(194, 194, 194, 0.5))
    );
    background-image: linear-gradient(
      0deg,
      rgba(194, 194, 194, 0.5) 0,
      rgba(194, 194, 194, 0.5) 100%
    );
    background-position: top 0 left 50%;
    background-repeat: no-repeat;
    background-size: calc(100% - 16px) 1px;
    padding-top: 11px;
  }
@media only screen and (max-width: 767px) {
.Footer__bottom {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
          -ms-flex-direction: column-reverse;
              flex-direction: column-reverse;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      padding-top: 26px
  }
    }
.Footer__socials {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    grid-gap: 16px;
    gap: 16px;
  }
@media only screen and (max-width: 767px) {
.Footer__socials {
      margin-bottom: 34px
  }
    }
@media (hover: hover) {
      .Footer__social:hover {
        opacity: 0.8;
      }
    }
.Footer__copy {
    color: #979797;
    color: var(--medium-gray);
    text-align: right;
  }
@media only screen and (max-width: 767px) {
.Footer .Accordion__title {
      font-size: 20px;
      line-height: 24px
  }
    }
.Footer__nav {
    display: block;
  }
@media only screen and (max-width: 767px) {
.Footer__nav {
      border-top: 0.5px solid #d9d9d9;
      border-top: 0.5px solid var(--light-gray);
      margin-bottom: -15px;
      padding-top: 19px
  }
    }
@media only screen and (max-width: 767px) {
.Footer__nav--one {
        border-top: none;
        margin-bottom: 0
    }
      }
.Footer__nav + .Footer__nav {
      margin-top: 46px;
    }
@media only screen and (max-width: 767px) {
.Footer__nav + .Footer__nav {
        margin-top: 0
    }
      }
.Footer__nav__item {
      display: block;
      margin-bottom: 8px;
    }
@media only screen and (max-width: 767px) {
.Footer__nav__item {
        margin-bottom: 16px;
        margin-left: 11px
    }
      }
.Footer__nav__item--gap {
        margin-bottom: 32px;
      }
.Footer__nav__item--title {
        font-weight: 600;
        margin-bottom: 18px;
      }
@media only screen and (max-width: 767px) {
.Footer__nav__item--title {
          border-bottom: 0.5px solid #d9d9d9;
          font-size: 20px;
          line-height: 24px;
          margin-left: 0;
          padding-bottom: 7px
      }
        }
@media only screen and (max-width: 767px) {
.Footer__nav--one .Footer__nav__item--title {
        border: none;
        padding-bottom: 0
    }
      }
.Footer__nav__link {
      color: #333333;
      color: var(--dark-gray);
      text-decoration: none;
    }
@media (hover: hover) {
        .Footer__nav__link:hover {
          opacity: 0.8;
        }
      }
.Footer__label {
    font-weight: 600;
    margin-bottom: 6px;
  }
@media only screen and (min-width: 768px) and (max-width: 991px) {
.Footer__label {
      margin-bottom: 15px
  }
    }
@media only screen and (max-width: 767px) {
.Footer__label {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 11px
  }
    }
