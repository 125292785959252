@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
:root {
  /* Design system colors */
  --tone-200: rgba(5, 5, 5, 0.06);
  --tone-300: rgba(0, 0, 0, 0.11);
  --tone-400: rgba(0, 0, 0, 0.28);
  --tone-500: rgba(0, 0, 0, 0.51);
  --tone-600: rgba(0, 0, 0, 0.65);
  --tone-700: rgba(0, 0, 0, 0.76);
  --tone-800: rgba(0, 0, 0, 0.86);
  --tone-900: rgba(0, 0, 0, 0.98);

  --absolute-100: #ffffff;
  --absolute-200: #f0f0f0;
  --absolute-300: #e3e3e3;
  --absolute-700: #3d3d3d;
  --absolute-800: #1f1f1f;
  --absolute-900: #050505;

  --aquamarine-500: rgba(1, 135, 144, 1);

  --red-100: rgba(228, 70, 86, 0.1);
  --red-500: rgba(211, 53, 69, 1);

  --blue-100: rgba(0, 122, 255, 0.1);
  --blue-400: rgba(0, 122, 255, 1);

  --text-secondary: rgba(0, 0, 0, 0.54);
  --text-disabled: rgba(0, 0, 0, 0.38);

  --white: white;
  --red: #ff3b2f;
  --light-red: #e44656;
  --transparent-red: rgba(228, 70, 86, 0.1);
  --light-footer-gray: #f1f1f1;
  --light-footer-bg-gray: #f8f8f8;
  --light-text-gray: rgba(0, 0, 0, 0.51);
  --text-black: rgba(0, 0, 0, 0.98);
  --text-black-title-popup: rgba(46, 46, 45, 1);
  --background-light-secondary: #f2f2f7;
  --popup-background: rgba(0, 0, 0, 0.28);
  --gray-border: rgba(0, 0, 0, 0.11);
  --gray-border-light: rgba(194, 194, 194, 0.5);
  --dark-gray: #333333;
  --light-gray: #d9d9d9;
  --medium-gray: #979797;
  --medium-dark-gray: #646464;
  --disabled-gray: #ebebeb;
  --disabled-dark-gray: #c2c2c2;
  --placeholder-gray: #f1f1f1;
  --white: white;
  --black: black;
  --light-black: rgba(61, 61, 61, 1);
  --main: #5648f8;
  --blue: rgba(0, 122, 255, 1);
  --link-blue: rgba(0, 106, 219, 1);
  --medium-main: #c1bbff;
  --light-main: #e7eafb;
  --accent: #22ccd8;
  --light-cookie-accent: rgba(233, 250, 251, 1);
  --light-accent: rgba(34, 204, 216, 0.2);
  --light-background-accent: rgba(144, 247, 254, 0.08);
  --lightest-accent: #b3eef2;
  --mts-red: #e30611;
  --yelow: #ff9f0a;
  --aquamarine: rgba(34, 204, 216, 0.1);

  /* New colors */
  --black-bg: var(--absolute-700);
  --black-hover: var(--absolute-800);

  /* Border-radius variants */
  --radius-sx: 4px;
  --radius-s: 8px;
  --radius-m: 16px;
  --radius-l: 24px;
  --radius-xl: 32px;

  /* Box-shadows */
  --shadow-xl: 0px 25px 59px rgba(0, 0, 0, 0.2);
  --shadow-m: 0px 16px 24px 0px rgba(0, 0, 0, 0.15);

  /**
   * Fonts using while preloading Lato
   * Or if preloading failed
   */
  --fonts: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;

  --font-family-main: Roboto;

  --font-size-heading-first: 30px;
  --font-size-heading-second: 22px;
  --font-size-heading-third: 18px;

  /**
   * Margin for the product card
   * Is using in the product grids and sliders
   */
  --product-margin: 8px;

  /**
   * Height of the `Header` component
   */
  --header-height: 84px;

  /**
   * Margins between content and page body
   * On desktop does not make sense because numeric value is unknown
   * 0 here on desktop to not to violate calculations
   */
  --wrapper-margin: 0;
  --wrapper-padding: 120px;

  /**
   * Cards spacings and width
   */

  --card-spacing: 16px;

  --card-width: 282px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
:root {
    --product-margin: 7.5px
}
  }
@media only screen and (max-width: 767px) {
:root {
    --product-margin: 5px
}
  }
@media only screen and (max-width: 767px) {
:root {
    --header-height: 54px
}
  }
@media only screen and (min-width: 768px) and (max-width: 991px),only screen and (max-width: 767px) {
:root {
    --wrapper-margin: 15px
}
  }
@media only screen and (min-width: 992px) {
:root {
    --card-spacing: 24px
}
  }
@media only screen and (min-width: 992px) {
:root {
    --card-width: calc((100% - (var(--card-spacing) * 2)) / 3)
}
  }
/* TODO: complete */
/* 1600 */
/* 1300 */
/* 992 */
/* 992 */
/* 768 */
/* 320 */
.Search {
  width: 100%;
  position: relative;
  color: #333333;
  color: var(--dark-gray);
}
.Search--big {
    font-size: 20px;
    width: 600px;
  }
@media only screen and (min-width: 768px) and (max-width: 991px),only screen and (max-width: 767px) {
.Search--big {
      font-size: 17px
  }
    }
.Search__row {
    position: relative;
    overflow: hidden;
    background: white;
    border-radius: 0.5em;
    -webkit-box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
  }
.Search--filled {
  }
.Search--results-visible .Search__row {
    border-radius: 0.5em 0.5em 0 0;
  }
.Search__results {
    width: 100%;
    position: absolute;
    z-index: 999;
    border-radius: 0 0 0.5em 0.5em;
    -webkit-box-shadow: 0 0.6em 1em rgba(0, 0, 0, 0.2);
            box-shadow: 0 0.6em 1em rgba(0, 0, 0, 0.2);
    overflow: auto;
    max-height: 70vh;
    background: white;
  }
.Search--big .Search__results {
    top: 3.5em;
  }
@media only screen and (min-width: 768px) and (max-width: 991px),only screen and (max-width: 767px) {
.Search--big .Search__results {
      top: 2.5em
  }
    }
.Search__icon {
    /* Change font-size because spinner size based on ems */
    width: 1.2em;
    font-size: 1em;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0.8em;
    color: #5648f8;
    color: var(--main);
    stroke-linecap: round;
  }
@media only screen and (min-width: 768px) and (max-width: 991px),only screen and (max-width: 767px) {
.Search__icon {
      font-size: 20px;
      width: 20px;
      height: 20px
  }
    }
.Header__search .Search__icon {
    font-size: 16px;
  }
.Search--big .Search__icon {
    font-size: 1.2em;
    right: 1.2em;
  }
@media only screen and (min-width: 768px) and (max-width: 991px),only screen and (max-width: 767px) {
.Search--big .Search__icon {
      right: 0.8em
  }
    }
.Search--filled .Search__icon--glass {
    display: none;
  }
.Search__input {
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border: none;
    outline: none;
    padding: 1.2em 3.6em 1.2em 1.2em;
    border-radius: inherit;
    font-family: inherit;
    font-size: 1em;
    font-weight: normal;
    color: inherit;
    caret-color: #5648f8;
    caret-color: var(--main);
  }
@media only screen and (min-width: 768px) and (max-width: 991px),only screen and (max-width: 767px) {
.Search__input {
      padding: 0.86em 2.6em 0.86em 0.86em;
      background-position: right 18px top 50%;
      background-size: 20px;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px
  }
    }
@media only screen and (max-width: 767px) {
.Search__input {
      font-size: 16px
  }
    }
.Search__input::-webkit-input-placeholder {
      color: #d9d9d9;
      color: var(--light-gray);
    }
.Search__input::-moz-placeholder {
      color: #d9d9d9;
      color: var(--light-gray);
    }
.Search__input:-ms-input-placeholder {
      color: #d9d9d9;
      color: var(--light-gray);
    }
.Search__input::-ms-input-placeholder {
      color: #d9d9d9;
      color: var(--light-gray);
    }
.Search__input::placeholder {
      color: #d9d9d9;
      color: var(--light-gray);
    }
.Search--stroked .Search__input {
    height: 44px;
    padding: 0.75em 0.66em;
    border: 1px solid #d9d9d9;
    border: 1px solid var(--light-gray);
  }
.Search--stroked .Search__input:focus,
    .Search--stroked .Search__input.focus {
      outline: 0;
      border-color: #22ccd8;
      border-color: var(--accent);
    }
.Search--werewolf.Search--touched .Search__input {
    border-color: transparent;
  }
.Search--stroked .Search__row {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
.Search--werewolf.Search--touched .Search__row {
    -webkit-box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
  }
.Search--inline {
    width: 100%;
    border: unset;
    border-radius: 0;
    overflow: visible;
  }
.Search--inline .Search__row {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
.Search--inline .Search__icon {
    right: 2.5em;
  }
.Search--inline .Search__input {
    height: 54px;
    padding: 12px 80px 12px 0;
    padding: 12px 80px 12px var(--wrapper-margin);
    padding-right: 80px;
    font-size: 16px;
    line-height: 17px;
  }
.Search--inline .Search__results {
    -webkit-box-shadow: 0 0.6em 1em rgba(0, 0, 0, 0.2);
            box-shadow: 0 0.6em 1em rgba(0, 0, 0, 0.2);
  }
@media only screen and (max-width: 767px) {
.Search--inline .SearchItem__row {
      padding-left: 0;
      padding-left: var(--wrapper-margin)
  }
    }
@media only screen and (max-width: 767px) {
.Search--xs-fullsize {
      position: absolute;
      left: 0
  }
    }
@media only screen and (max-width: 767px) {
.Search--xs-fullsize .Search__results {
      border-radius: 0
  }
    }
.Search .Checkbox {
    margin-top: 9px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
.Search .Checkbox__label {
      font-weight: 400;
      font-size: 16px;
      line-height: 22.4px;
      letter-spacing: 0%;
    }
@media only screen and (max-width: 767px) {
.Search .Checkbox__label {
        color: white
    }
      }
@media only screen and (max-width: 767px) {
.Search .Checkbox .Checkbox__graphics {
        background-color: white
    }

        .Search .Checkbox .Checkbox__graphics svg path {
          fill: black;
        }
      }
