.icon-container {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow: hidden;
  color: inherit;
  vertical-align: middle;
  cursor: inherit;
  fill: none;
}

.icon-container-dynamic {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 var(--icon-width);
          flex: 0 0 var(--icon-width);
  min-width: var(--icon-width);
  max-width: var(--icon-width);
  min-height: var(--icon-height);
  max-height: var(--icon-height);
}

