@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
:root {
  /* Design system colors */
  --tone-200: rgba(5, 5, 5, 0.06);
  --tone-300: rgba(0, 0, 0, 0.11);
  --tone-400: rgba(0, 0, 0, 0.28);
  --tone-500: rgba(0, 0, 0, 0.51);
  --tone-600: rgba(0, 0, 0, 0.65);
  --tone-700: rgba(0, 0, 0, 0.76);
  --tone-800: rgba(0, 0, 0, 0.86);
  --tone-900: rgba(0, 0, 0, 0.98);

  --absolute-100: #ffffff;
  --absolute-200: #f0f0f0;
  --absolute-300: #e3e3e3;
  --absolute-700: #3d3d3d;
  --absolute-800: #1f1f1f;
  --absolute-900: #050505;

  --aquamarine-500: rgba(1, 135, 144, 1);

  --red-100: rgba(228, 70, 86, 0.1);
  --red-500: rgba(211, 53, 69, 1);

  --blue-100: rgba(0, 122, 255, 0.1);
  --blue-400: rgba(0, 122, 255, 1);

  --text-secondary: rgba(0, 0, 0, 0.54);
  --text-disabled: rgba(0, 0, 0, 0.38);

  --white: white;
  --red: #ff3b2f;
  --light-red: #e44656;
  --transparent-red: rgba(228, 70, 86, 0.1);
  --light-footer-gray: #f1f1f1;
  --light-footer-bg-gray: #f8f8f8;
  --light-text-gray: rgba(0, 0, 0, 0.51);
  --text-black: rgba(0, 0, 0, 0.98);
  --text-black-title-popup: rgba(46, 46, 45, 1);
  --background-light-secondary: #f2f2f7;
  --popup-background: rgba(0, 0, 0, 0.28);
  --gray-border: rgba(0, 0, 0, 0.11);
  --gray-border-light: rgba(194, 194, 194, 0.5);
  --dark-gray: #333333;
  --light-gray: #d9d9d9;
  --medium-gray: #979797;
  --medium-dark-gray: #646464;
  --disabled-gray: #ebebeb;
  --disabled-dark-gray: #c2c2c2;
  --placeholder-gray: #f1f1f1;
  --white: white;
  --black: black;
  --light-black: rgba(61, 61, 61, 1);
  --main: #5648f8;
  --blue: rgba(0, 122, 255, 1);
  --link-blue: rgba(0, 106, 219, 1);
  --medium-main: #c1bbff;
  --light-main: #e7eafb;
  --accent: #22ccd8;
  --light-cookie-accent: rgba(233, 250, 251, 1);
  --light-accent: rgba(34, 204, 216, 0.2);
  --light-background-accent: rgba(144, 247, 254, 0.08);
  --lightest-accent: #b3eef2;
  --mts-red: #e30611;
  --yelow: #ff9f0a;
  --aquamarine: rgba(34, 204, 216, 0.1);

  /* New colors */
  --black-bg: var(--absolute-700);
  --black-hover: var(--absolute-800);

  /* Border-radius variants */
  --radius-sx: 4px;
  --radius-s: 8px;
  --radius-m: 16px;
  --radius-l: 24px;
  --radius-xl: 32px;

  /* Box-shadows */
  --shadow-xl: 0px 25px 59px rgba(0, 0, 0, 0.2);
  --shadow-m: 0px 16px 24px 0px rgba(0, 0, 0, 0.15);

  /**
   * Fonts using while preloading Lato
   * Or if preloading failed
   */
  --fonts: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;

  --font-family-main: Roboto;

  --font-size-heading-first: 30px;
  --font-size-heading-second: 22px;
  --font-size-heading-third: 18px;

  /**
   * Margin for the product card
   * Is using in the product grids and sliders
   */
  --product-margin: 8px;

  /**
   * Height of the `Header` component
   */
  --header-height: 84px;

  /**
   * Margins between content and page body
   * On desktop does not make sense because numeric value is unknown
   * 0 here on desktop to not to violate calculations
   */
  --wrapper-margin: 0;
  --wrapper-padding: 120px;

  /**
   * Cards spacings and width
   */

  --card-spacing: 16px;

  --card-width: 282px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
:root {
    --product-margin: 7.5px
}
  }
@media only screen and (max-width: 767px) {
:root {
    --product-margin: 5px
}
  }
@media only screen and (max-width: 767px) {
:root {
    --header-height: 54px
}
  }
@media only screen and (min-width: 768px) and (max-width: 991px),only screen and (max-width: 767px) {
:root {
    --wrapper-margin: 15px
}
  }
@media only screen and (min-width: 992px) {
:root {
    --card-spacing: 24px
}
  }
@media only screen and (min-width: 992px) {
:root {
    --card-width: calc((100% - (var(--card-spacing) * 2)) / 3)
}
  }
/* TODO: complete */
/* 1600 */
/* 1300 */
/* 992 */
/* 992 */
/* 768 */
/* 320 */
/* TODO: remove global css overrides */
/* TODO: rewrite margins */
.h1 {
  font-weight: 600;
  font-size: 30px;
  line-height: 1.2em;
  margin: 0.67em 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px),only screen and (max-width: 767px) {
.h1 {
    /* TODO: refactor margins into sustainable system */
    margin-top: 40px !important;
    margin-bottom: 20px !important;
    font-weight: 500;
    font-size: 24px
}
  }
h2,
.h2 {
  color: #333333;
  color: var(--dark-gray);
  font-size: 24px;
  font-weight: 500;
  line-height: 22px;
}
.Subtitle {
  margin: 11px 0 10px 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px),only screen and (max-width: 767px) {
.Subtitle {
    margin: -8px 0 7px 0;
    font-size: 17px;
    line-height: 20px
}
  }
.light {
  font-weight: 200;
}
.bold {
  font-weight: 600;
}
.lh-1-4 {
  line-height: 1.4;
}
.t-11 {
  font-size: 11px;
}
.t-13 {
  font-size: 13px;
}
.t-15 {
  font-size: 15px;
}
.t-17 {
  font-size: 17px;
}
.t-24 {
  font-size: 24px;
}
.t-m {
  font-weight: 500;
}
.t-inactive {
  color: #979797;
  color: var(--medium-gray);
}
.h-17 {
  font-size: 17px;
  font-weight: 600;
  /* TODO: maybe alter style for different styles */
  line-height: 129%;
}
.h-20 {
  font-size: 20px;
  line-height: 120%;
}
.h-22 {
  font-size: 22px;
  line-height: 100%;
  font-weight: 500;
  font-family: 'Roboto';
}
.h-24 {
  font-size: 24px;
  font-weight: 500;
  line-height: 120%;
}
/* TODO: refactor to few heading styles */
.h-30 {
  font-size: 30px;
  font-weight: 500;
  line-height: 120%;
}
@media only screen and (max-width: 767px) {
.h-30 {
    font-size: 24px;
    line-height: 90%
}
  }
.m-2 {
  margin-bottom: 2px;
}
.m-4 {
  margin-bottom: 4px;
}
.m-8 {
  margin-bottom: 8px;
}
.m-10 {
  margin-bottom: 10px;
}
.m-12 {
  margin-bottom: 12px;
}
.m-16 {
  margin-bottom: 16px;
}
.m-16-t {
  margin-top: 16px;
}
.m-20 {
  margin-bottom: 20px;
}
.m-24 {
  margin-bottom: 24px;
}
.m-32 {
  margin-bottom: 32px;
}
.m-32-t {
  margin-top: 32px;
}
.m-40 {
  margin-bottom: 40px;
}
.m-24-t {
  margin-top: 24px;
}
.m-40 {
  margin-bottom: 40px;
}
.p-24-t {
  padding-top: 24px;
}
.m-48 {
  margin-bottom: 48px;
}
.m-48-adaptive {
  margin-bottom: 48px;
}
@media only screen and (min-width: 768px) and (max-width: 991px),only screen and (max-width: 767px) {
.m-48-adaptive {
    margin-bottom: 32px
}
  }
.m-64 {
  margin-bottom: 64px;
}
.m-64-t {
  margin-top: 64px;
}
.tab-like-link {
  position: relative;
  top: 2px;
  margin-right: 32px;
  margin-bottom: 0;
  border-bottom: 4px solid rgba(34, 204, 216, 0.2);
  border-bottom: 4px solid var(--light-accent);
  border-color: rgba(0, 0, 0, 0);
  padding-bottom: 8px;
  color: #979797;
  color: var(--medium-gray);
  cursor: pointer;
  -webkit-transition: border-color 0.3s ease;
  transition: border-color 0.3s ease;
}
@media only screen and (min-width: 768px) and (max-width: 991px),only screen and (max-width: 767px) {
.tab-like-link {
    margin-right: 18.5px
}
  }
@media (hover: hover) {
    .tab-like-link:hover {
      border-bottom: 4px solid #b3eef2;
      border-bottom: 4px solid var(--lightest-accent);
    }
  }
.tab-like-link--active {
    border-color: rgba(34, 204, 216, 0.2);
    border-color: var(--light-accent);
    color: black;
    color: var(--black);
    pointer-events: none;
    cursor: pointer;
    -webkit-animation: stronger 0.3s;
            animation: stronger 0.3s;
  }
@media only screen and (max-width: 767px) {
  .m-32-xs {
    margin-bottom: 32px;
  }

  .m-48-xs {
    margin-bottom: 48px;
  }
}
.with-no-grow {
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}
